.display_slider {
  display: none;
}
#accordition .accorditionelement {
  border-left-width: 4px;
  border-color: #dcffcf;

  padding-left: 30px;
}

#accordition .Mui-expanded {
  margin: 16px 0;
  border-color: #00b27a;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

#accordition .MuiAccordion-root:first-of-type {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

#accordition .MuiAccordion-root:last-of-type {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

#accordition .MuiAccordion-root:before {
  background-color: transparent;
}
:root {
  --swiper-navigation-color: #000000;
}
.swiper-button-prev,
.swiper-button-next {
  width: auto;
}

.swiper-button-next:after {
  content: "";
  background-image: url("./arrow_circle_right.png");
  width: 50px;
  height: 50px;
}
.swiper-button-prev:after {
  content: "";
  background-image: url("./arrow_circle_left.png");
  width: 50px;
  height: 50px;
}

@media (max-width: 835px) {
  .display_slider {
    display: block !important;
  }
  .swiper-button-next {
    top: 50%;
    right: 5px;
  }
  .swiper-button-prev {
    top: 50%;
    left: 5px;
  }
}
@media (max-width: 550px) {
  .swiper-button-next,
  .swiper-button-prev {
    top: 55%;
  }
}
