.title {
  font-size: 1.5rem;
}
.table {
  overflow-x: hidden;
}
@media (max-width: 835px) {
  .table {
    overflow-x: scroll;
  }
  .title {
    font-size: 1rem;
  }
}
