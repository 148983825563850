.form_container {
  border: 3px solid #000;
  padding: 15px 30px;
}
.title {
  color: var(--green, #00b27a);
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 30px;
}
.text {
  color: #475467;
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.text_container {
  margin: auto;
}
@media (max-width: 1100px) {
  .text_container {
    width: 100% !important;
    text-align: center;
    margin-bottom: 30px;
  }
  .form_container {
    width: 100% !important;
  }
}
