.title {
  color: var(--rcblue, #114653);
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
}

.text {
  color: var(--rcblue, #114653);
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 150% */
  max-width: 486px;
}

.trial_button {
  position: relative;
  margin: 0 !important;
  width: 100%;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  max-width: 324px;
}

.button_container {
  justify-content: start;
}

.container {
  height: 469px;
}

.img_container {
  height: 338px;
  margin: auto;
}

.item_text {
  color: #114653;
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 1100px) {
  .container {
    width: 80% !important;
    height: auto;
  }

  .text_container {
    width: 100% !important;
  }

  .title {
    font-size: 36px;
    text-align: center;
  }

  .trial_button {
    font-size: 16px;
  }

  .text {
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    margin: auto;
  }

  .img_container {
    width: 100% !important;
    justify-content: center !important;
    height: auto;
    margin-top: 20px;
  }

  .button_container {
    justify-content: center;
  }
}