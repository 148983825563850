.mobile_socials {
  display: none;
}

.desktop_socials {
  display: flex;
}

.block {
  width: 25%;
}

.landing_block {
  width: 33.333333%;
}

.blocks_container {
  flex-direction: row;
}

@media (max-width: 835px) {
  .blocks_container {
    flex-direction: column;
  }

  .block {
    width: 100%;
  }

  .mobile_socials {
    display: flex;
  }

  .desktop_socials {
    display: none;
  }
}

@media (max-width: 480px) {
  .blocks_container {
    flex-direction: column;
  }

  .block {
    width: 100%;
  }

  .landing_block {
    width: 100%;
  }
}
