.sticky_picture {
  position: sticky;
  top: 30%;
}
.display_first_frame {
  display: block !important;
}
.display_second_frame {
  display: block !important;
}
.display_third_frame {
  display: block !important;
}
.hide_first_frame {
  display: none !important;
}
.hide_second_frame {
  display: none !important;
}
.hide_third_frame {
  display: none !important;
}
