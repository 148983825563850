.title {
  color: var(--rcblue, #114653);
  font-family: Brevia;
  font-size: 48px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 100px;
}

.text {
  color: #475467;
  text-align: center;
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.img_item {
  padding: 1rem;
  align-items: center;
  width: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: center;


}

.img_sub_title {
  color: #114653;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.img_sub_text {
  color: #114653;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;

}

.img {
  margin: auto;
  max-width: 100%;
  height: 200px;
}

.img_container {
  display: flex;

}

.text_container {
  height: 250px;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  max-width: 965px;
  gap: 1rem;
}


.button {
  display: inline-flex;
  padding: 11px 35.5px 9.732px 35.5px;
  justify-content: center;
  align-items: center;
  border: 2.964px solid #000;
  background: #DCFFCF;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 42.967px;
}

@media (max-width: 1100px) {
  .img_item {
    width: 100% !important;
  }
}

@media (max-width: 600px) {

  .title {
    font-size: 36px;
  }

  .img_sub_text {
    font-size: 18px;
  }

  .text_container {
    height: 350px;
  }
}
