.displayid {
  display: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.strikethrough {
  position: relative;
}

.strikethrough:before {
  border-bottom: 5px solid red;
  position: absolute;
  content: "";
  width: 100%;
  height: 95%;
  left: -12px;
  transform: rotate(-40deg);
}

/* pagina css start */
.pagina-banner .row-pagina-main {
  background: linear-gradient(
      80.87deg,
      #dcffcf -25.85%,
      rgba(220, 255, 207, 0) 62.27%
    ),
    #114653;
  padding: 50px 0 0;
}
.pagina-banner-text {
  text-align: center;
}
.pagina-banner-text p {
  color: #fff;
}
.pagina-banner-para {
  font-size: 16px;
  width: 70%;
  margin: 0 auto;
  font-weight: 400;
}
.pagina-banner-btn p {
  color: #000;
}
.pagina-sec-2-bg {
  background-color: #f5f5f5;
  padding: 50px 0;
}
.pagina-sec-2-text {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
}
.pagina-sec-2-text span {
  font-weight: 600;
}
.main-counter {
  font-family: poppins;
  display: flex;
}

.main-counter button {
  font-weight: 600;
  background-color: #fff;
  padding: 10px;
  min-width: 55px;
  font-size: 17px;
  border: 3px solid;
  margin-bottom: 9px;
}

.main-counter > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}

.main-counter sub {
  display: block;
  font-size: 13px;
  font-weight: 600;
}
.counter-btn button {
  background-color: #00b27a;
  margin-top: 40px;
}
.counter-btn button p {
  color: #fff;
}
/* pagina css end */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.sticky-picture {
  position: sticky;
  top: 30%;
}
.alignProperty > div {
  background-color: #114653;
}

.twop {
  display: none;
}
.threep {
  display: none;
}
.alignProperty .onep {
  display: none;
}
.alignProperty .twop {
  display: block;
}
.last > div {
  background-color: #fff;
}
.last .onep {
  display: none;
}
.last .twop {
  display: none;
}
.last .threep {
  display: block;
}
