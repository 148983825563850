@media (max-width: 1100px) {
  .container {
    padding: 0;
  }
}
@media (max-width: 1000px) {
  .container {
    width: 500px;
    margin: auto;
  }
}
@media (max-width: 600px) {
  .container {
    width: 90%;
    margin: auto;
  }
}
