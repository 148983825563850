.title {
  color: var(--green, #00b27a);
  font-family: Brevia;
  font-size: 48px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}
.text {
  color: var(--gray-600, #475467);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
  max-width: 486px;
}
.trial_button {
  position: relative;
  margin: 0 !important;
  width: 100%;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  max-width: 324px;
}
.button_container {
  justify-content: start;
}

.container {
  height: 469px;
}
.img_container {
  height: 338px;
  margin: auto;
}
@media (max-width: 1100px) {
  .container {
    width: 80% !important;
    height: auto;
  }
  .text_container {
    width: 100% !important;
  }
  .title {
    font-size: 36px;
    text-align: center;
  }
  .trial_button {
    font-size: 16px;
  }
  .text {
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    margin: auto;
  }
  .img_container {
    width: 100% !important;
    justify-content: center !important;
    height: auto;
    margin-top: 20px;
  }
  .button_container {
    justify-content: center;
  }
}
