.backBtn {
  
  box-shadow: 0px 5px 9px -2px rgb(0 0 0 / 25%);
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  color: #fff;
  height: 40px;
  justify-content: center;

}