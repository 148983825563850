.img_container {
  min-width: 300px;
}
.title {
  margin-bottom: 22px;
  line-height: 1;
}
.icons_section {
  margin-top: 54px;
}
@media (max-width: 1140px) {
  .img_container {
    min-width: 200px;
    height: 300px;
  }
}
@media (max-width: 1000px) {
  .img_container {
    min-width: 300px;
    height: 300px;
    margin: auto;
    margin-top: 40px;
  }
  .text_container {
    width: 100% !important;
    text-align: center;
  }
  .container {
    padding-left: 0 !important;
  }
  .icons_section {
    width: fit-content;
    margin: auto;
  }
}
@media (max-width: 500px) {
  .title {
    font-size: 36px;
  }
  .icons_section {
    margin-top: 11px;
  }
  .icons_section {
    width: 90%;
    margin: auto;
  }
  .container {
    width: 90% !important;
  }
}
