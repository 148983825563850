@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-button {
  width: max-content;
  display: inline-flex;
  padding: 4px 72px 5px 71px;
  justify-content: center;
  align-items: center;
  border: 3px solid #000;
  background: #dcffcf;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 42.967px;
}

.stroke {
  background: transparent !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Brevia850,
.Brevia700,
.pop700,
.pop600,
.pop500,
.pop400,
.pop300 {
  font-family: "Poppins", sans-serif;
}

.Brevia850 {
  font-weight: 850;
}

.Brevia700 {
  font-weight: 700;
}

.pop700 {
  font-weight: 700;
}

.pop600 {
  font-weight: 600;
}

.pop500 {
  font-weight: 500;
}

.pop400 {
  font-weight: 400;
}

.pop300 {
  font-weight: 300;
}

.fw-bold {
  font-weight: 600 !important;
}

.lead {
  font-size: 1.3125em;
  font-weight: 400;
}

.MuiListItemText-root {
  font-weight: 600;
}

.MuiAccordion-root {
  box-shadow: none !important;
}

td,
th {
  border: 1px solid #114653;
  text-align: left;
  padding: 8px;
}

table {
  box-shadow: 11px 9px 0px -1px rgba(17, 70, 83, 1);
  -webkit-box-shadow: 11px 9px 0px -1px rgba(17, 70, 83, 1);
  -moz-box-shadow: 11px 9px 0px -1px rgba(17, 70, 83, 1);
}

/* Scrollbar styles */

html:hover,
::-webkit-html:hover {
  overflow-y: overlay;
}

.scroller,
::-webkit-html {
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #dcffcf;
}

::-webkit-scrollbar-thumb {
  background: #00b27a;
  height: 100px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #00b27a;
}

/* Border image */
.borderimg1 {
  border: 50px solid transparent;
  border-image: url(./Assets/border.svg) 10% stretch;
}

/* Media queries */
@media (min-width: 366px) and (max-width: 426px) {
  .alignment-form .w-1\/3 {
    width: 100%;
  }

  .alignment-form .align-item-end {
    align-items: center;
  }

  .ani-txt {
    flex-direction: column;
    text-align: center;
  }

  .animation-color-div-flex {
    flex-direction: column;
  }

  .ani-img {
    width: 54%;
    margin: 20px 20px;
  }

  .full-img {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .same-height {
    min-height: auto;
    margin-bottom: 30px;
  }

  .compare-div .w-1\/2 {
    width: 100%;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

h1 {
  font-family: "Brevia";
  font-weight: 950;
  font-size: 3.75em;
}

h2 {
  font-family: "Brevia Italic";
  font-weight: 700;
  font-size: 3.75em;
}

h3 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 2.25em;
}

h4 {
  font-size: 1.5em;
  font-family: "Poppins";
  font-weight: 700;
}

h5 {
  font-size: 1.3em;
  font-family: "Poppins";
  font-weight: 600;
}

p {
  font-size: 1em;
  font-family: "Poppins";
  font-weight: 400;
}

.small {
  font-size: 0.9em;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
