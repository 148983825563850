.title {
  color: var(--green, #00b27a);
  font-family: Brevia;
  font-size: 48px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 30px;
}
.text {
  color: #475467;
  text-align: center;
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.text_container {
  max-width: 965px;
  margin: auto;
}
.img_item {
  position: relative;
  display: flex;
  width: 30%;
  padding: 0px 24px 32px 24px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  border-radius: 16px;
  background: var(--gray-50, #f9fafb);
  margin-top: 136px;
  min-height: 146px;
}
.img_sub_text {
  margin-top: auto;
  color: var(--gray-900, #101828);
  text-align: center;
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  height: 60px;
}
.img {
  position: absolute;
  bottom: 70%;
}
.more_bottom_img {
  bottom: 60% !important;
}
@media (max-width: 1100px) {
  .img_item {
    width: 100% !important;
  }
}
@media (max-width: 600px) {
  .title {
    font-size: 36px;
  }
  .img_sub_text {
    font-size: 18px;
  }
}
