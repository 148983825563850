.container {
  max-width: 930px;
  margin-left: 10%;
}
.svg_container svg {
  height: 50px;
  width: 50px;
}
.text {
  color: var(--gray-600, #475467);
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}
@media (max-width: 650px) {
  .container {
    padding: 0 0 3rem 0 !important;
  }
  .sub_container {
    margin-top: 0 !important;
  }
  .sub_container h1 {
    font-size: 2rem;
    text-align: center !important;
  }
  .sub_container h6 {
    font-size: 1rem;
  }
  .svg_container svg {
    height: 30px;
    width: 30px;
  }
  .text {
    font-size: 18px;
    line-height: 30px;
  }
}
