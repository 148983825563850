.img_container {
  margin-top: 50px;
  margin-bottom: -300px;
  margin-left: auto;
  margin-right: auto;
}

.main_container {
  height: 400px;
  align-self: center;
}

@media (max-width: 1500px) {
  .img_container {
    right: 20px;
    bottom: 10px;
  }
}

@media (max-width: 1100px) {
  .items_container {
    margin-bottom: 30px;
  }
}

@media (max-width: 850px) {
  .img_container {
    top: auto;
    padding: 45px;
  }

  .main_container {
    height: 300px;
    align-self: center;
  }
}

@media (max-width: 600px) {
  .img_container {
    top: auto;
    width: 97%;
  }

  .main_container {
    height: 150px;
    align-self: center;
    margin-top: 150px;
  }
}

@media (max-width: 400px) {
  .img_container {
    top: auto;
    width: 97%;
  }

  .main_container {
    height: 150px;
    align-self: center;
    margin-top: 150px;
  }
}
