.scrolled_section {
  height: 400px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 835px) {
  .no_padding_in_mobile {
    padding: 0 !important;
  }
  .scrolled_section {
    height: auto;
    padding-bottom: 50px !important;
  }
}
