.title {
  color: var(--green, #00b27a);
  text-align: center;
  font-family: Brevia;
  font-size: 48px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
}
.card_title {
  color: #114653;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
}
.item_text {
  color: var(--gray-600, #475467);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.left_bar {
  width: 10px;
  height: 47px;
  flex-shrink: 0;
  border-radius: 30px;
  background: var(--dark-blue, #114653);
  margin-right: 26px;
}
.extra_text {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
@media (max-width: 1500px) {
  .text_container {
    padding: 0 10px !important;
  }
  .extra_text_container {
    padding: 0 10px !important;
  }
}
@media (max-width: 1100px) {
  .card_container {
    width: 100% !important;
  }
}
@media (max-width: 600px) {
  .title {
    font-size: 36px;
  }
  .main_container {
    width: 90% !important;
  }
  .container {
    width: 100% !important;
  }
}
