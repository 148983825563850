.items_container {
  height: 250px;
}
.img_container {
  top: -50px;
  width: 550px;
  height: 550px;
  right: 105px;
  z-index: 9;
}
.item_text {
  color: var(--white, #fff);
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 18px;
}
.trail_button {
  position: relative;
  margin: 0 !important;
  width: 50% !important;
  min-width: 200px;
}
.title {
  color: var(--white, #fff);
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media (max-width: 1500px) {
  .img_container {
    width: 400px;
    height: 400px;
    right: 20px;
    bottom: 10px;
  }
}
@media (max-width: 1100px) {
  .main_container {
    overflow: hidden;
  }
  .container {
    width: 60% !important;
  }
  .img_container {
    top: auto;
    width: 450px;
    height: 450px;
    bottom: -90px;
    right: -90px;
  }
  .items_container {
    height: auto;
    margin-bottom: 30px;
  }
  .built_for {
    width: 50% !important;
  }
}
@media (max-width: 850px) {
  .container {
    height: 885px;
  }
  .img_container {
    top: auto;
    width: 600px;
    height: 600px;
    bottom: -30%;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .built_for {
    width: 100% !important;
  }
  .img_wrapper {
    width: 100% !important;
  }
}
@media (max-width: 600px) {
  .container {
    height: 685px;
    width: 80% !important;
  }
  .img_container {
    top: auto;
    width: 400px;
    height: 400px;
    bottom: -30%;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .built_for {
    width: 100% !important;
  }
  .img_wrapper {
    width: 100% !important;
  }
  .items_container {
    width: fit-content;
    margin: auto;
    margin-bottom: 30px;
  }
  .title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 10px;
  }
  .trail_button {
    width: 100% !important;
  }
}
@media (max-width: 400px) {
  .img_container {
    top: auto;
    width: 300px;
    height: 300px;
    bottom: -20%;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
