.text_container {
  margin: auto;
}
.title {
  color: #00b27a;
  font-family: Brevia;
  font-size: 60px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
}
.paragraph {
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 24px 0 42px 0;
}
.picture_container {
  height: max-content;
  min-width: 260px;
}
.only_on_small_devices {
  display: none;
}
@media (max-width: 850px) {
  .custom_button {
    border: 0;
    text-decoration: underline;
    padding: 0;
    margin: auto;
  }
  .picture_container {
    width: 100% !important;
    order: 2;
  }
  .text_container {
    width: 100% !important;
    text-align: center;
    order: 1;
  }
  .only_on_small_devices {
    display: block;
  }
  .title {
    font-size: 40px;
  }
}
