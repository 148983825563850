.bg {
  background-color: #114653;
}
.mobile {
  display: none;
}
.desktop {
  display: block;
}
@media (max-width: 835px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}
