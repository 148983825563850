.container {
  text-align: center;
  background: #00b27a;
  margin: 80px 0;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
}
.title {
  color: #FFF;
  font-family: Brevia;
  font-size: 48px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  margin-left: 100px;
}

.text{
  margin-top: 80px;
}


@media (max-width: 430px) {
  .title {
    font-size: 24px;
  }
  .container {
    height: auto;
    padding: 64px 0;
    margin: 31px 0;
  }
  
}
