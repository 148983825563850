.title {
  color: var(--gray-500, #667085);
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.item_text {
  color: var(--gray-500, #667085);
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 18px;
}
.img_container img {
  height: 310px;
  margin-right: 50px;
}
.packages_container {
  height: 260px;
}
@media (max-width: 1100px) {
  .img_container {
    order: 1;
    margin: auto;
  }
  .text_container {
    width: 100% !important;
  }
  .packages_container {
    height: auto;
  }
}
@media (max-width: 700px) {
  .img_container {
    display: none !important;
  }
}
@media (max-width: 600px) {
  .title {
    font-size: 24px;
    text-align: center;
  }
  .container {
    width: 80% !important;
  }
  .item_text {
    font-size: 18px;
  }
  .packages_container {
    width: fit-content;
    margin: auto;
  }
}
@media (max-width: 400px) {
  .title {
    text-align: left;
  }
}
