.card_container {
  width: 380px;
  min-width: 320px;
  height: 480px;
}
.price {
  color: var(--gray-800, #1d2939);
  text-align: center;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.96px;
}
.iva {
  color: var(--gray-600, #475467);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.type_addition {
  color: var(--gray-800, #1d2939);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin: 17px 0 34px 0;
}
.choose_one {
  color: var(--gray-600, #475467);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 25px 0 16px 0;
}
.middle_card {
  min-height: 550px;
  margin: 0 20px;
  box-shadow: 10px 10px #114653;
}
.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background: var(--dark-blue, #114653);
  width: 240px;
  height: 37px;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -15px;
  right: 20px;
}
.badge img {
  margin-right: 21px;
  width: 18px;
  height: 25px;
  flex-shrink: 0;
}
.item_text {
  color: var(--gray-600, #475467);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-left: 12px;
}
.svg_container {
  border-radius: 12px;
  background: var(--primary-100, #d6fff2);
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.read_more {
  color: var(--gray-600, #475467);
  text-align: right;
  font-family: Poppins;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
}
@media (max-width: 1439px) {
  .card_container {
    width: 280px;
    min-width: 280px;
    height: 480px;
  }
}
@media (max-width: 1000px) {
  .card_container {
    width: 380px;
    min-width: 320px;
  }
  .middle_card {
    order: -1;
    margin: 0;
    margin-bottom: 5px;
    box-shadow: 0 0;
  }
  .first_card {
    margin-bottom: 5px;
  }
}
@media (max-width: 957px) {
  .middle_card {
    order: -1;
  }
  .first_card {
    margin-right: 0;
  }
  .card_container {
    margin-bottom: 10px;
  }
  .badge {
    right: 75px !important;
  }
}
@media (max-width: 500px) {
  .badge {
    right: 45px !important;
  }
}
