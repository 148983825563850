.container {
  text-align: center;
  height: 310px;
  background: #00b27a;
  padding: 96px 0;
  margin: 80px 0;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
}
.title {
  color: #fff;
  font-size: 36px;
  line-height: normal;
}
.button_wrapper {
  margin-top: 20px;
}
.button {
  display: flex;
  width: 415px;
  padding: 11px 137.5px 9.732px 138.5px;
  justify-content: center;
  align-items: center;
  border: 2.964px solid #000;
  background: #dcffcf;
  color: #000;
  font-size: 21px;
  line-height: 42.967px;
  margin: auto;
}

@media (max-width: 430px) {
  .title {
    font-size: 24px;
  }
  .container {
    height: auto;
    padding: 64px 0;
    margin: 31px 0;
  }
  .button_wrapper {
    margin-top: 32px;
  }
  .button {
    padding: 14px 100px 15px 100px;
    width: auto;
    font-size: 18px;
  }
}
