.hideMenuNav {
  display: none;
}

.showMenuNav {
  display: block;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: white;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.mobile_nav {
  display: none;
  position: relative;
  z-index: 10;
  background: #ffffff;
}
@media (max-width: 1100px) {
  .mobile_nav {
    display: block;
  }
}
